import {
  CreateCustomOptionModal,
  SelectFactory,
  SelectTypes,
  Option,
  CustomOptionMenuList,
  MultiSelectDropdownIndicator,
} from '@g17eco/molecules';
import { useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import { initCustomOptions } from './selectUtils';

interface SelectComponentParams {
  options: Option<string>[];
  setOptions: React.Dispatch<React.SetStateAction<Option<string>[]>>;
  value: Option<string>[];
  placeholder: string | undefined;
  allowCustomOptions: boolean;
  isDisabled?: boolean;
  handleChange: (newValues: string[] | string) => void;
  isMulti: boolean;
  limit?: number;
}

export const SelectComponent = (props: SelectComponentParams) => {
  const {
    placeholder,
    allowCustomOptions = false,
    isDisabled = false,
    handleChange,
    value,
    options,
    setOptions,
    isMulti = false,
    limit,
  } = props;

  const [showCreateModal, setShowCreateModal] = useState(false);

  const onChangeHandler = (newValue: string | null | string[], actionMeta?: ActionMeta<Option<string> | null>) => {
    if (newValue === null) {
      return;
    }
    if (Array.isArray(newValue)) {
      if (actionMeta?.action === 'create-option') {
        setOptions(initCustomOptions(newValue, options, allowCustomOptions));
      }
      handleChange(newValue);
      return;
    }

    if (actionMeta?.action === 'create-option') {
      setOptions(initCustomOptions([newValue], options, allowCustomOptions));
    }
    handleChange(newValue);
  };

  const baseSelectProps = { placeholder, isDisabled, options, className: 'w-100 valueList-react-select' };

  if (allowCustomOptions) {
    const onAddOption = (option: string) => {
      const newValue = isMulti ? [...value.map((v) => v.value), option] : option;
      onChangeHandler(newValue, { action: 'create-option', option: { label: option, value: option } });
    };

    return (
      <>
        {showCreateModal ? (
          <CreateCustomOptionModal addOption={onAddOption} toggle={() => setShowCreateModal(false)} />
        ) : null}
        {isMulti ? (
          <SelectFactory
            selectType={SelectTypes.CreatableMultipleSelect}
            {...baseSelectProps}
            onChange={(values: string[], actionMeta?: ActionMeta<Option<string> | null>) =>
              onChangeHandler(values, actionMeta)
            }
            values={value.map((op) => op.value)}
            components={{ MenuList: (props) => CustomOptionMenuList({ ...props, setShowCreateModal }) }}
            showSelectedOptions
            isFlexibleSize
          />
        ) : (
          <SelectFactory
            selectType={SelectTypes.CreatableSelect}
            {...baseSelectProps}
            onChange={(op: SingleValue<Option<string> | null>) => onChangeHandler(op?.value ?? null)}
            value={value[0] ?? null}
            components={{ MenuList: (props) => CustomOptionMenuList({ ...props, setShowCreateModal }) }}
          />
        )}
      </>
    );
  }

  return isMulti ? (
    <SelectFactory
      selectType={SelectTypes.MultipleSelect}
      {...baseSelectProps}
      onChange={(values: string[], actionMeta?: ActionMeta<Option<string> | null>) =>
        onChangeHandler(values, actionMeta)
      }
      values={value.map((op) => op.value)}
      components={{
        DropdownIndicator: () => <MultiSelectDropdownIndicator current={value.length} limit={limit} />,
      }}
      showSelectAll={limit !== undefined}
      showSelectedOptions
      isFlexibleSize
      hideCheckboxOnDisabled={false}
    />
  ) : (
    <SelectFactory
      selectType={SelectTypes.SingleSelect}
      {...baseSelectProps}
      onChange={(op: SingleValue<Option<string> | null>) => onChangeHandler(op?.value ?? null)}
      value={value[0] ?? null}
    />
  );
};
