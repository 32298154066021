import { DropdownIndicator } from './SelectFactory';

interface Props {
  current: number;
  limit?: number;
}

export const MultiSelectDropdownIndicator = ({ current, limit }: Props) => {
  if (!limit) {
    return <DropdownIndicator />;
  }

  const hasReachedLimit = current === limit;

  return (
    <div className='d-flex align-items-center'>
      <span className={`text-sm ${hasReachedLimit ? 'text-danger' : 'text-ThemeTextMedium'}`}>Limit: {current + '/' + limit}</span>
      <DropdownIndicator />
    </div>
  );
};
